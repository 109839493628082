<template>
  <div class="content p-t-10 p-l-10 p-r-10">
    <van-field class="tw-rounded-sm" v-model="form.code" @keypress.enter="track" center clearable label="二维码" autocomplete="off" placeholder="请输入二维码" required>
      <template #button>
        <van-button color='#5B9DFF' size="small" type="primary" @click="track">追踪</van-button>
      </template>
    </van-field>
    <!-- result 结果 -->
    <div>
      <div id="step  " class="tw-bg-white m-b-20" v-if="detail.code_assembly_line&&detail.code_assembly_line.length">
        <div class="stepContent" :class="{'is_up':!is_down}" >
           <van-steps direction="vertical" :active="0" active-color="#4290FF">
          <van-step v-for="(v,k) in detail.code_assembly_line" :key="k">
            <h3 class="m-b-10">【{{v.status_text}}】{{v.add_time}}</h3>
            <p class="s_lin_h22" v-for="(items,index) in v.content" :key="index">{{items}}</p>
          </van-step>
        </van-steps>
        </div>
        <div class="tw-text-center  p-b-10 p-t-10" v-if="detail.code_assembly_line.length>3" @click="is_down=!is_down">
          {{is_down?'收起':"查看更多"}}
          <van-icon name="arrow-down" v-if="is_down" />
          <van-icon name="arrow-up" v-else />
        </div>
      </div>
      <van-list class="p-b-20" v-if="detail.code_base_info&&detail.code_base_info">
        <van-cell title="二维码编号" :value="detail.code_base_info.code_sn" />
        <van-cell title="码状态" :value="detail.code_base_info.status_text" />
        <van-cell title="生产批次"  :value="detail.code_base_info.batch_sn"/>
        <van-cell title="商品名称" :value="detail.code_base_info.product_name || '-'" />
        <!-- <van-cell title="商品规格" :value="detail.code_base_info.product_sku_sn" /> -->
        <van-cell title="二维码类型" :value="detail.code_base_info.code_type_text" />
        <van-cell title="备注" :value="detail.code_base_info.remark" />
      </van-list>
    </div>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {getCodeTrack} from '@/api/track'
import { Toast } from 'vant';
import {iscode} from '@/utils/app'
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const form = ref({
      code: "", //
    });
    const detail=ref({})
    const audios=ref('')
    const track = async () => {
      if(!form.value.code){
        Toast("请输入物流码")
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      let result=await getCodeTrack(form.value).then(res=>res.data).catch(error=>error)
      Toast.clear();
      if(iscode(result)){
        detail.value=result.data
      }else{
        iscode(result,true)
        form.value.code=''
        audios.value.error()
      }
    };
    const is_down=ref(false)
    onMounted(()=>{

    })
    return {
      form,
      track,
      detail,
      is_down,
      audios
    };
  },
};
</script>
<style lang="scss" scoped>
.stepContent{

}
.is_up{
 max-height: 300px;
  overflow: hidden;
}
</style>
